import React from "react";
import { useEffect, useState } from "react";
import "../styles/Landing.css";
import mainimage from "../Assets/images/main-img.webp";
import dayfree from "../Assets/images/day-free.webp";

type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  inputRef: React.RefObject<HTMLInputElement>[];
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  error: string;
  showError: boolean;
  handleSubmitBtn: () => void;
  showLoader: boolean;
  timer: number;
  onResendOtpPressed: () => void;
};
const OTPPage = (props:Props) => {
  return (
    <div className="landing-bg">
      <div className="subscrption-content">
        <div className="subscrption-image">
          <img src={mainimage} width={375} height={334} alt="main image" />
        </div>
        <div className="form">
          <img
            src={dayfree}
            className="dayf"
            width={105}
            height={102}
            alt="try 1 day free"
          />
          <div className="input-content">
            <div className="head-number">
              PLAY AND LEARN WITH YOUR FAVORITE DISNEY CHARACTERS!
            </div>
            <div className="text">YOUR MOBILE NUMBER:</div>
            {/* <div className="otp-inputs">
              <input type="tel" name="one" maxLength={1} autoFocus />
              <input type="tel" name="two" maxLength={1} />
              <input type="tel" name="three" maxLength={1} />
              <input type="tel" name="four" maxLength={1} />
            </div> */}
            <div className="otp-inputs">
            <input
              type="tel"
              name="one"
              maxLength={1}
              autoFocus
              value={props.otp.one}
              onChange={(e) => {
                props.handleChangeOTP(e, 0);
              }}
              ref={props.inputRef[0]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 0);
              }}
            />
            <input
              type="tel"
              name="two"
              maxLength={1}
              value={props.otp.two}
              onChange={(e) => {
                props.handleChangeOTP(e, 1);
              }}
              ref={props.inputRef[1]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 1);
              }}
            />
            <input
              type="tel"
              name="three"
              maxLength={1}
              value={props.otp.three}
              onChange={(e) => {
                props.handleChangeOTP(e, 2);
              }}
              ref={props.inputRef[2]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 2);
              }}
            />
            <input
              type="tel"
              name="four"
              maxLength={1}
              value={props.otp.four}
              onChange={(e) => {
                props.handleChangeOTP(e, 3);
              }}
              ref={props.inputRef[3]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 3);
              }}
            />
          </div>
          {props.showError ? (
            <>
              <p className="invalid">{props.error}</p>
            </>
          ) : (
            <></>
          )}
          </div>
          {/*  */}

          <div className="resend-code">
            {props.timer <= 0  ? (
              <div className="timer-text">
                Didn’t Receive OTP?{" "}
                <span onClick={props.onResendOtpPressed}>Resend</span>
              </div>
            ) : (
              <div className="not-received">
                 Resend OTP in 00:{props.timer}
              </div>
            )}
          </div>
          {/*  */}
          {props.showLoader ? (
            <button className="lp-loader">Loading...</button>
          ) : (
            <button
              onClick={() => {
                props.handleSubmitBtn();
              }}
            >
              Subscribe
            </button>
          )}

          <div className="price-points">
            Service cost: 10 PKR / daily (Inc tax).
          </div>

          <div className="condition">
            <a href="http://jazz.magicclub.mobi/view/tnc" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPPage;
