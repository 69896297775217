import React from "react";
import "../styles/Landing.css";
import mainimage from "../Assets/images/main-img.webp";
import dayfree from "../Assets/images/day-free.webp";

type Props = {
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  handleOnPressOTPBtn: () => void;
  errorMsg: string;
  showLoader: boolean;
};
const LandingPage = (props: Props) => {
  return (
    <div className="landing-bg">
      <div className="subscrption-content">
        <div className="subscrption-image">
          <img src={mainimage} width={375} height={334} alt="main image" />
        </div>
        <div className="form">
          <img
            src={dayfree}
            className="dayf"
            width={105}
            height={102}
            alt="try 1 day free"
          />
          <div className="input-content">
            <div className="head-number">
              PLAY AND LEARN WITH YOUR FAVORITE DISNEY CHARACTERS!
            </div>
            {/* <div className="text">YOUR MOBILE NUMBER:</div>
            <div className="code-number">
              <div className="code">
                <div className="fixed-cnt">+92</div>
              </div>
              <div className="input">
                <input
                  type="tel"
                  value={props.phoneNo}
                  placeholder="3xx-xxxx-xxx"
                  max={10}
                  onChange={(e) => {
                    props.handlePhoneNoChange(e);
                  }}
                />
              </div>
            </div> */}
          </div>
          {props.errorMsg ? (
            <>
              <p className="error">{props.errorMsg}</p>
            </>
          ) : (
            <></>
          )}
          {props.showLoader ? (
            <button>Loading...</button>
          ) : (
            <button
              onClick={() => {
                props.handleOnPressOTPBtn();
              }}
            >
              Get OTP
            </button>
          )}

          <div className="price-points">
            Service cost: 10 PKR / daily (Inc tax).
          </div>

          <div className="condition">
            <a href="http://jazz.magicclub.mobi/view/tnc" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
