import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Component/LandingPage";
import OTPPage from "./Component/OTPPage";
import LandingPageScreen from "./Screens/LandingPageScreen";
import OtpPageScreen from "./Screens/OtpPageScreen";
import { fetchApiPost } from "./utils/FetchApi";
import { ApiName } from "./utils/ApiName";
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import AwarenessScreen from "./Screens/AwarenessScreen";


function App() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PRKRBM4J",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      console.log(data)
      const response = await fetchApiPost("", `${ApiName.generateToken}`, data);
      if (response.status === 0) {
        setToken(response.response.token);
      }else{
        console.log("Something went wrong")
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<LandingPageScreen token={token}/>}></Route>
          <Route path="/landing" element={<LandingPageScreen token={token}/>}></Route>
          <Route path="/pin" element={<OtpPageScreen token={token}/>}></Route>
          <Route path="/awareness" element={<AwarenessScreen token={token} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
