import React from "react";
import MainImage from "../Assets/images/awareness-content.webp";
import "../styles/Awareness.css";
type Props = {};

const Awareness = (props: Props) => {
  return (
    <>
      <div className="awareness-page">
        <div className="image">
          <img
            src={MainImage}
            width={340}
            height={598}
            alt="awareness main image"
          />
        </div>
      </div>
    </>
  );
};

export default Awareness;
